export const PATH = {
  ADDRESS_DETAILS: '/my-quotes/address-details',
  DIMENSIONS: '/my-quotes/dimensions',
  QUOTE_DETAIL: '/my-quotes',
  REQ_MY_QUOTE: '/quotes/request',
  FEEDBACK_LIST: '/feedback',
  TARGET_SET: '/set-target',
  CUSTOMER_DETAILS: `/customers`,
  CUSTOMER_GROUP_DETAILS: `/customers/customers-group`,
  REQ_ADDRESS_DETAILS: '/quotes/request/address-details',
  REQ_DIMENSIONS: '/quotes/request/dimensions',
  TEAM_MANAGEMENT: '/team-management',
  LOAD_INSURANCE: '/load-insurance',
  CLAIMS_AND_DISPUTES_DETAILS: `/claims-and-disputes`,
  CLAIM_DETAILS: `/claims-and-disputes/claim-details`,
  DISPUTE_DETAILS: `/claims-and-disputes/dispute-details`,
  CUSTOMER_ONBOARDING: `/customer-onboarding`,
  CREDIT_DASHBOARD: `/credit-dashboard`,
  SUBMISSION: `/customer-onboarding/submission`,
  THANK_YOU: `/customer-onboarding/thank-you`,
  COMMISSION_REPORT: `/sales/commission-report`,
  ORDER_DETAILS: `/order-details`,
};
