import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const getUser = async () => {
  const response = await axiosInterceptor.get(API.USER.GET);

  return response;
};

// export const login = async () => {
//   const response: any = await axiosInterceptor.get(API.USER.LOGIN);
//   const params = new URLSearchParams(response.data.url);
//   const verifier: any = params.get('verifier');
//   localStorage.clear();
//   localStorage.setItem('verifier', verifier);

//   return response;
// };

export const login = async (payload: any) => {
  const response: any = await axiosInterceptor.post(API.AUTH.LOGIN, payload);

  localStorage.setItem('code', response?.code || null);

  return response;
};

export const resetNewPassword = async (payload: any) => {
  const response: any = await axiosInterceptor.post(
    API.AUTH.RESET_PASSWORD,
    payload
  );
  localStorage.setItem('token', response?.token || null);

  return response;
};

export const verify = async (payload: any) => {
  const response: any = await axiosInterceptor.post(
    API.AUTH.VERIFICATION,
    payload
  );

  return response;
};

export const verifyPassword = async (payload: any) => {
  const response: any = await axiosInterceptor.post(
    API.AUTH.VERIFY_PASSWORD,
    payload
  );
  localStorage.setItem('token', response?.token || null);

  return response;
};

export const sendVerificationMail = async (payload: any) => {
  const response: any = await axiosInterceptor.post(
    API.AUTH.VERIFICATION_MAIL,
    payload
  );
  localStorage.setItem('code', response?.token || null);

  return response;
};

export const verifyOtp = async (payload: any) => {
  const response: any = await axiosInterceptor.post(
    API.AUTH.VERIFY_OTP,
    payload
  );

  return response;
};

export const getDetail = async (custId: any) => {
  const response: any = await axiosInterceptor.get(
    `${API.USER.GET_DETAIL}/${custId}`
  );

  return response;
};

export const onboardSetDetails = async (payload: any) => {
  const response: any = await axiosInterceptor.post(
    API.AUTH.SET_ONBOARD,
    payload
  );

  return response;
};
