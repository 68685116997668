export const API = {
  AUTH: {
    LOGIN: '/auth/login',
    VERIFICATION: '/auth/verification',
    LOGOUT: '/user/logout',
    VERIFICATION_MAIL: '/auth/verification-mail',
    VERIFY_OTP: '/auth/verify-otp',
    RESET_PASSWORD: '/auth/reset-password',
    VERIFY_PASSWORD: '/auth/verify-password',
    SET_ONBOARD: '/auth/sign-up',
  },
  SALES_DASHBOARD: {
    TEAM_LIST: 'sales/team-list',
  },
  USER: {
    GET: '/user/get',
    GET_DETAIL: '/user/get-detail',
  },
  QUOTE: {
    GET: '/quote',
    GET_USER_QUOTES: '/user/quotes',
    LIST: '/quote',
    QUOTELIST_COUNT: '/quote-count',
    GET_ANALYTICS: 'quote/analytics',
    GET_ANALYTICS_TEAM: '/quote/team',
    CREATE: '/quote/address',
    UPDATE: '/quote/address',
    CREATE_UPDATE_DIMENSION: '/quote/dimension',
    CREATE_UPDATE_SERVICE: '/quote/service',
    CREATE_UPDATE_SUMMARY: '/quote/summary',
    CREATE_RATE: '/quote/rate',
    UPDATE_RATE: '/quote/rate',
    CANCEL: '/quote/cancel',
    QUICK_QUOTE: '/quote/quick-quote',
    ACTIVE_QUOTE: '/quote/active-quote',
    DELETE_ACTIVE_QUOTE: '/quote/delete-active-quote',
    CREATE_PDF: '/quote/pdf',
    UPDATE_PDF: '/quote/pdf',
    LIST_PDF: '/quote/pdf',
    UPDATE_STATUS: '/quote/status',
    PUSH_TO_ROSE_ROCKET: '/quote/roserocket-order',
    BOOK_ORDER: '/quote/book-order',
    CHECK_BOL: '/quote/check-shipment-bol',
    DELETE: '/quote',
    MODIFY_QUOTE: '/quote/modifyquote',
    GET_FREIGHT_CLASS: '/quote/dimension/get-freight-class',
    MAKE_NO_QUOTE: '/quote/make-no-quote',
    LOAD_LINK: {
      GET_VEHICLE_TYPE: '/load-link/vehicle_type',
    },
  },
  HANDLING_UNIT: {
    LIST: '/handling-unit',
  },
  SERVICES: {
    LIST: '/service',
  },
  CURRENCY_RATE: {
    GET: '/currency/rate',
  },
  INTEGRATIONS: {
    MAP_SERVICE: '/integrations/map-service',
  },
  SHIPPER: {
    LIST: '/shipper',
    CREATE: '/shipper',
    DELETE: '/shipper',
  },
};
