import React from 'react';

const Dashboard = () => {
  console.log('dashboard');

  return (
    <>
      <section className="main__layout_section flex flex-col">
        Test Dashboard
      </section>
    </>
  );
};

export default Dashboard;
