export interface IListOption {
  value: string | number;
  label: string;
  subLabel?: string;
  image?: string;
  email?: string;
}

export interface IFilterProps {
  grid: boolean;
  searchText: string | null;
  perPageLimit: number;
  sortValue: string;
  sortText: string;
  orderValue: string;
  currentPage: number;
}

export interface ITabOption {
  value: string;
  name: string;
}

export type BadgeType =
  | 'success'
  | 'error'
  | 'warning'
  | 'primary'
  | 'info'
  | 'gray'
  | 'red'
  | 'lightblue'
  | 'yellow'
  | 'orange'
  | 'pink';

export const TimeRanges = [
  { label: 'Last 7 days', value: 'last_7_days', key: 'last_7_days' },
  { label: 'Last 30 days', value: 'last_30_days', key: 'last_30_days' },
  { label: 'Last 90 days', value: 'last_90_days', key: 'last_90_days' },
  { label: 'Last Year', value: 'last_year', key: 'last_year' },
  { label: 'Weekly', value: 'weekly', key: 'weekly' },
  { label: 'Monthly', value: 'monthly', key: 'monthly' },
  { label: 'Quarterly', value: 'quarterly', key: 'quarterly' },
  { label: 'Yearly', value: 'yearly', key: 'yearly' },
  { label: 'All Time', value: 'all_time', key: 'all_time' },
  // { label: 'Today', value: 'today', key: 'today' },
  { label: 'Custom', value: 'custom', key: 'custom' },
];
