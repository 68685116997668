import React from 'react';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';

const QuoteDetail = () => (
  <div>
    <PageSectionLayout
      header={
        <Header
          title="Quoting Dashboard"
          desc="Create, view and manage your customer quotes."
          mainContainerClassName="flex-nowrap !flex-row"
          leftClassName="!w-auto"
          rightClassName="!w-auto"
        />
      }
    >
      Quote Detail Page
    </PageSectionLayout>
  </div>
);

export default QuoteDetail;
