export const APP_FULL_NAME: string = window.FULL_NAME as string;
export const REGION_CODE: any = window.REGION_CODE;
export const TIMEZONE: any = window.TIMEZONE;
// export const DATE_FORMAT: string = 'DD/MM/YYYY';
export const DATE_FORMAT: string = 'MMM DD, YYYY';
export const TIME_FORMAT: string = 'hh:mm:ss A';

export const FOOT = 5;
export const HANDLING_UNIT_NUMBER = 999;

export const ROSE_ROCKET = {
  QUOTE_URL: window.ROSE_ROCKET_QUOTE_URL,
};

export const CARRIERS = {
  ARC_BEST: {
    ID: 1,
    QUOTE_URL: window.ARCBEST_QUOTE_URL,
    BOL_URL: window.ARCBEST_BOL_URL,
    SLUG: 'ARC_BEST',
  },
  POLARIS: {
    ID: 2,
    QUOTE_URL: window.POLARIS_QUOTE_URL,
    BOL_URL: window.POLARIS_BOL_URL,
    SLUG: 'POLARIS',
  },
  DAY_ROSS: {
    ID: 3,
    QUOTE_URL: window.DAY_ROSS_QUOTE_URL,
    BOL_URL: window.DAY_ROSS_BOL_URL,
    SLUG: 'DAY_ROSS',
  },
  FREIGHTCOM: {
    ID: 5,
    QUOTE_URL: window.FREIGHTCOM_QUOTE_URL,
    BOL_URL: window.FREIGHTCOM_BOL_URL,
    SLUG: 'FREIGHTCOM',
  },
  MARITIME_ONTARIO: {
    ID: 4,
    QUOTE_URL: window.MARITIME_ONTARIO_QUOTE_URL,
    BOL_URL: window.MARITIME_ONTARIO_BOL_URL,
    SLUG: 'MARITIME_ONTARIO',
  },
  CSA: {
    ID: 6,
    QUOTE_URL: window.CSA_QUOTE_URL,
    BOL_URL: window.CSA_BOL_URL,
    SLUG: 'CSA',
  },
  KINDERSLEY: {
    // QUOTE_URL: window.CSA_QUOTE_URL,
    // BOL_URL: window.CSA_BOL_URL,
    SLUG: 'KINDERSLEY',
  },
  DAY_ROSS_USA: {
    SLUG: 'DAY_ROSS_USA',
  },
  BANYAN: {
    SLUG: 'BANYAN',
  },
};

export const MARGIN_TYPE = {
  VALUE: 'VALUE',
  PERCENTAGE: 'PERCENTAGE',
};

// export const MEASUREMENT = {
//     WEIGHT1: "LBS",
//     WEIGHT2: "KGS",

//     LENGTH1: "INCHES",
//     LENGTH2: "CM",
// };

export const MEASUREMENT = {
  WEIGHT1: {
    value: 'LBS',
    label: 'LBS',
  },
  WEIGHT2: {
    value: 'KGS',
    label: 'KGS',
  },
  LENGTH1: {
    value: 'INCHES',
    label: 'IN',
  },
  LENGTH2: {
    value: 'CM',
    label: 'CM',
  },
};

export const LBS = {
  WEIGHT: 6000,
  LENGTH: 144, // Inches
  WIDTH: 96, // Inches
  HEIGHT: 96, // Inches
  ITEM_LENGTH_OVER: FOOT * 12, // Inches
  MAX_WEIGHT: 2500,
};

export const KGS = {
  WEIGHT: Math.ceil(LBS.WEIGHT / 2.2046),
  LENGTH: Math.ceil(LBS.LENGTH / 0.3937), // CM
  WIDTH: Math.ceil(LBS.WIDTH / 0.3937), // CM
  HEIGHT: Math.ceil(LBS.HEIGHT / 0.3937), // CM
  ITEM_LENGTH_OVER: Math.ceil(FOOT * 30.48), // CM
  MAX_WEIGHT: Math.ceil(LBS.MAX_WEIGHT / 2.2046),
};

export const STATUS = {
  PENDING: 'Pending',
  OPENED: 'Opened',
  ACTIVE: 'Active',
  WON: 'Won',
  LOST: 'Lost',
  EXPIRED: 'Expired',
  QUOTE_REQUEST: 'QuoteRequest',
};

export const CURRENCY = {
  USD: 'USD',
  CAD: 'CAD',
};

export const VALIDATION_MESSAGE = {
  INVALID_ADDRESS:
    'City was not detected by Google Maps. Please specify manually or drag the icon on the map',
  INVALID_PICKUP_QUOTE:
    'Pickup is not likely to happen on this date. Would you like to quote for next available business day?',
};

export const PDF_LANGUAGE_OPTIONS = [
  { value: 'English', label: 'English' },
  { value: 'French', label: 'French' },
];

export const PDF_LANGUAGE = {
  ENG: 'English',
  FRE: 'French',
};

export const USER_ROLE = {
  ADMIN: 'admin',
  MANAGER: 'manager',
  USER: 'user',
  SALES: 'sales',
  OPERATIONS: 'operations',
  CLAIMS: 'claims',
  FINANCE: 'finance',
};

export const PERMISSION = {
  USER_LIST: 'team_member_list',
  USER_CREATE: 'team_member_create',
  USER_UPDATE: 'team_member_update',
  USER_DELETE: 'team_member_delete',
  TEAM_LIST: 'team_list',
  TEAM_CREATE: 'team_create',
  TEAM_UPDATE: 'team_update',
  TEAM_DELETE: 'team_delete',
  ORGANIZATIONAL_CHART: 'ORGANIZATIONAL_chart',
  QUOTING_HUB_VIEW: 'quoting_hub_view',
  QUOTING_HUB_CREATE: 'quoting_hub_create',
  QUOTING_HUB_UPDATE: 'quoting_hub_update',
  QUOTING_HUB_DELETE: 'quoting_hub_delete',
  OPERATIONS_VIEW: 'operations_view',
  OPERATIONS_CREATE: 'operations_create',
  OPERATIONS_UPDATE: 'operations_update',
  OPERATIONS_DELETE: 'operations_delete',
  CARRIER_VIEW: 'carrier_view',
  CARRIER_CREATE: 'carrier_create',
  CARRIER_UPDATE: 'carrier_update',
  CARRIER_DELETE: 'carrier_delete',
  OVERVIEW: 'dashboard_overview',
  ADVANCE_VIEW: 'advance_view',
  FILE_UPLOAD: 'file_upload',
  FILE_DOWNLOAD: 'file_download',
  FILLE_DELETE: 'file_delete',
  TARGET_VIEW: 'target_view',
  TARGET_UPDATE: 'target_update',
  CUSTOMER_LIST: 'customer_list',
  CUSTOMER_DETAIL: 'customer_detail',
  CUSTOMER_GROUP_DETAIL: 'customer_group_detail',
  QUOTING_REQUEST: 'quoting_request',
  QUOTING_ANYLYTICS: 'quoting_analytics',
  VIEW_SMART_QUOTE: 'view_smart_quote',
  CREATE_SMART_QUOTE: 'create_smart_quote',
  DELETE_SMART_QUOTE: 'delete_smart_quote',
  LANE_HISTORY: 'lane_history',
  RATE_SHOPPING: 'rate_shopping',
  TRACK_AND_TRACE: 'track_and_trace',
  ORDERS: 'orders',
  RADAR: 'radar',
  COMMISSION_REPORT: 'commission_report',
};

export const CUSTOMER_SEGMENT_TYPES = {
  NEW: 'new',
  TOP: 'top',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  AT_RISK: 'at-risk',
  DORMANT: 'dormant',
  PROSPECT: 'prospect',
  TEST: 'test',
};

export const ONBOARDING_CUSTOMER_STATUS = {
  FORM_PENDING: 'Form Pending',
  IN_REVIEW: 'In Review',
  FORM_COMPLETED: 'Form Completed',
  APPROVED: 'Approved',
  DENIED: 'Denied',
  NEW: 'New',
  EXPIRED: 'Expired',
};

export const LOAD_INSURANCE_STATUS = {
  APPROVED: 'Approved',
  DECLINED: 'Declined',
  OPEN: 'Open',
};

export const TABLE_IDS = {
  QUOTE_LIST: 'quoteList',
  CUSTOMER_LIST: 'customerList',
  TARGET_LIST: 'targetList',
  SALES_DASHBOARD: 'sales_dashboard',
  TARGET_LIST_BY_CUSTOMER: 'targetListByCustomer',
  CLAIMS_AND_DISPUTES_LIST: 'claimsAndDisputes',
  CREDIT_DASHBOARD: 'creditDashboard',
  CUSTOMER_DETAILS: 'customerDetail',
};

export const FILE_ORDER_STATUS = {
  NEW: 'New',
  NO_QUICK_QUOTE: 'No Quick Quote',
  NO_QUOTE: 'No Quote',
  QUICK_QUOTED: 'Quick Quoted',
  QUOTED: 'Quoted',
  SAVED: 'Saved',
  SPOT_QQ_REQUESTED: 'Spot Qq Requested',
  CANCELLED: 'cancelled',
  DRAFT_QUICK_QUOTE: 'draft quick quote',
  CLAIM: 'claim',
  ARCHIVED: 'archived',
  INVOICE_SENT: 'invoice sent',
  INVOICE_PAID: 'invoice paid',
  DISPATCHED: 'dispatched',
  INVOICE_CREATED: 'invoice created',
  DELIVERED: 'delivered',
  IN_TRANSIT: 'in transit',
};

export const QUOTE_CLASSIFICATION = {
  LTL: 'ltl',
  FTL: 'ftl',
  VOLUME_LTL: 'volume_ltl',
};

export const MAX_FILE_SIZE = 2 * 1024 * 1024;
export const MAP_API: string = 'google_map';
// export const TRIMBLE_KEY = 'D2D11E9BF803B7499B3051535FC9AC14';
// export const MAP_API: string = 'trimble_map';
export const TRIMBLE_KEY = '707CC148BE16B64A8102C686C8B8DBF3';

export const orgTypeArr = [
  { label: 'Public Company', value: 'public_company' },
  { label: 'LLC', value: 'llc' },
  { label: 'Private Corporation', value: 'private_corporation' },
  { label: 'Partnership', value: 'partnership' },
  { label: 'Sole Proprietorship', value: 'sole_proprietorship' },
  { label: 'Government', value: 'government' },
  { label: 'Other', value: 'other' },
];

export const CREDIT_INCREASE_REQUEST_STATUS_TYPES = {
  NEW: 'new',
  APPROVED: 'approved',
  IN_REVIEW: 'in_review',
  DENIED: 'denied',
};
export const TIME_FILTER: { value: string; name: string }[] = [
  {
    value: 'daily',
    name: 'Today',
  },
  {
    value: 'weekly',
    name: 'This Week',
  },
  {
    value: 'monthly',
    name: 'This Month',
  },
  {
    value: 'yearly',
    name: 'This Year',
  },
];
export const RECORDS_PER_PAGE = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

export const AI_CHATBOT_APP_API_URL =
  'https://wal-py-ai-gbabdjc2dgh4htgs.canadacentral-01.azurewebsites.net';

export const scriptId = 'google-maps-api';
