import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from 'src/context/AuthContext';
import { resetNewPassword } from 'src/services/AuthService';

import successfullyicon from '../../assets/img/successfullyicon.svg';
import { ROUTES } from '../../constants/routes';
import AuthWrapper from '../AppLayout/AuthWrapper';

import UpdatePasswordForm from './UpdatePasswordForm';

const initValidForm = {
  password: true,
  confirm_password: true,
};

const SetPassword = () => {
  const location = useLocation();
  const { setIsAuthenticated } = useContext(AuthContext);
  const [validForm, setValidForm] = useState(initValidForm);
  const [code, setCode] = useState<string | null>(null);

  const [submittedForm, setSubmittedForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const initFormData: any = {
    email: '',
    password: '',
  };

  const [formData, setFormData] = useState<any>(initFormData);
  const [errorMsg, setErrorMsg] = useState<any>('');

  const valid = () => {
    let isValid = true;
    const validFormData = {
      password: true,
      confirm_password: true,
    };

    if (formData.password === '' || formData.password === null) {
      validFormData.password = false;
      isValid = false;
    }

    if (formData?.password && formData?.password?.length < 8) {
      validFormData.password = false;
      isValid = false;
    }

    if (
      formData?.confirm_password &&
      formData?.confirm_password !== formData?.password
    ) {
      validFormData.confirm_password = false;
      isValid = false;
    }

    setValidForm({ ...validForm, ...validFormData });

    return isValid;
  };

  const handleInputChange = (event: any) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event: any) => {
    setErrorMsg('');
    event.preventDefault();

    if (!valid()) {
      return true;
    }
    setIsLoading(true);
    const payload = {
      password: formData?.password,
      confirm_password: formData?.confirm_password,
      code: code,
    };

    resetNewPassword(payload)
      .then((response: any) => {
        setIsLoading(false);

        if (response?.token) {
          localStorage.setItem('token', response?.token || null);
          setSubmittedForm(true);
        }
      })
      .catch((error): any => {
        setIsLoading(false);
        setErrorMsg(
          error?.response?.message || 'Something went wrong with set password'
        );
      });
  };

  useEffect(() => {
    const codeVal =
      new URLSearchParams(location?.search).get('code') || (null as any);

    if (!codeVal) {
      navigate(ROUTES?.LOGIN);
    }
    setCode(encodeURIComponent(codeVal));
  }, []);

  return (
    <>
      <AuthWrapper>
        {!submittedForm && (
          <div className="form_height">
            <div className="w-full">
              <div className="flex flex-col">
                <div className="relative mb-6 lg:mb-4 xl:mb-6">
                  <div className="w-full flex items-center justify-center pb-3 ">
                    {/* <img src={mailicon} alt="mailicon" className="w-[54px]" /> */}
                  </div>
                  <h1 className="title_text text-center">Set Password</h1>
                  <p className="text-sm font-normal mt-2 text-[#475467] text-center">
                    Your new password must be strong.
                  </p>
                </div>
                <UpdatePasswordForm
                  formData={formData}
                  errorMsg={errorMsg}
                  validForm={validForm}
                  isLoading={isLoading}
                  handleSubmit={handleSubmit}
                  handleInputChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        )}
        {submittedForm && (
          <div className="form_height text-center">
            <div className="w-full">
              <div className="w-full flex items-center justify-center pb-3">
                <img
                  src={successfullyicon}
                  alt="mailicon"
                  className="w-[54px]"
                />
              </div>
              <h1 className="text-3xl xxl:text-4xl -tracking-[0.24px] md:-tracking-[0.384px] font-semibold pb-4 text-[#000000E5]">
                Password Reset
              </h1>
              <p className="text-base font-normal text-center text-[#475467] pb-6 pt-1">
                Your password has been successfully reset. Click below to
                redirect to dashboard
              </p>
              <div className="w-full btn_primary mt-1 text-center">
                <button
                  className="cursor-pointer "
                  onClick={() => setIsAuthenticated(true)}
                >
                  <span className="text-base font-semibold text-white w-full">
                    Continue
                  </span>
                </button>
              </div>
            </div>
          </div>
        )}
      </AuthWrapper>
    </>
  );
};

export default SetPassword;
