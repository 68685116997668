import React from 'react';
import { BrowserRouter, Route, Routes as ReactRoutes } from 'react-router-dom';
import Dashboard from 'src/app/Dashboard';
import ForgotPassword from 'src/app/Login/ForgotPassword';
import VerifyPassword from 'src/app/Login/VerifyPassword';
import MyQuote from 'src/app/MyQuote';
import AddressDetailsForm from 'src/app/MyQuote/AddressDetails/AddressDetailsForm';
import Dimensions from 'src/app/MyQuote/Dimensions';
import QuoteDetail from 'src/app/MyQuote/Quote';
import SetDetails from 'src/app/SetDetails';
import SetPassword from 'src/app/SetPassword';
import CreatePassword from 'src/app/SetPassword/CreatePassword';
import Verification from 'src/app/Verification';

import AppLayout from '../app/AppLayout';
// import Callback from '../app/Callback';
import PageNotFound from '../app/Errors/404';
import Login from '../app/Login';
import { PERMISSION } from '../constants/common';
import { ROUTES } from '../constants/routes';

import OnboardingRoute from './OnboardingRoute';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const Routes = () => (
  <BrowserRouter>
    <ReactRoutes>
      <Route path={ROUTES.HOME} element={<AppLayout />}>
        <Route
          path={ROUTES.LOGIN}
          element={<PublicRoute component={Login} title="Login" />}
        />
        <Route
          path={ROUTES.SET_PASSWORD}
          element={<PublicRoute component={SetPassword} title="SetPassword" />}
        />
        <Route
          path={ROUTES.VERIFICATION}
          element={
            <PublicRoute component={Verification} title="Verification" />
          }
        />

        <Route
          path={ROUTES.FORGOTPASSWORD}
          element={
            <PublicRoute component={ForgotPassword} title="Forgot Password" />
          }
        />

        <Route
          path={ROUTES.SET_DETAILS}
          element={
            <OnboardingRoute component={SetDetails} title="Set Details" />
          }
        />
        <Route
          path={ROUTES.VERIFY_PASSWORD}
          element={
            <PublicRoute component={VerifyPassword} title="Verify Password" />
          }
        />

        <Route
          path={ROUTES.CREATE_PASSWORD}
          element={
            <PublicRoute component={CreatePassword} title="create Password" />
          }
        />
        <Route
          index
          path={ROUTES.HOME}
          element={
            <PrivateRoute
              component={Dashboard}
              title="Smart Quote"
              permission={PERMISSION.VIEW_SMART_QUOTE}
            />
          }
        />
        <Route
          index
          path={ROUTES.MY_QUOTE}
          element={
            <PrivateRoute
              component={MyQuote}
              title="Smart Quote"
              permission={PERMISSION.VIEW_SMART_QUOTE}
            />
          }
        />
        <Route
          index
          path={ROUTES.ADDRESS_DETAILS}
          element={
            <PrivateRoute
              component={AddressDetailsForm}
              title="Address Details"
              permission={PERMISSION.CREATE_SMART_QUOTE}
            />
          }
        />

        <Route
          path={ROUTES.DIMENSIONS}
          element={
            <PrivateRoute
              component={Dimensions}
              title="Dimensions"
              permission={PERMISSION.CREATE_SMART_QUOTE}
            />
          }
        />

        <Route
          path={ROUTES.QUOTE_DETAIL}
          element={
            <PrivateRoute
              component={QuoteDetail}
              title="Quote Detail"
              permission={PERMISSION.CREATE_SMART_QUOTE}
            />
          }
        />
        {/* <Route
          index
          path={ROUTES.ORGANISATION}
          element={
            <PrivateRoute
              component={Organisation}
              title="Register"
              permission={true}
            />
          }
        /> */}
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </ReactRoutes>
  </BrowserRouter>
);

export default Routes;
