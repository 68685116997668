import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import { ROUTES } from 'src/constants/routes';
import { AuthContext } from 'src/context/AuthContext';
import { verifyPassword } from 'src/services/AuthService';

import eyeclose from '../../assets/img/eye-off.svg';
import eyeone from '../../assets/img/eye.svg';
import AuthWrapper from '../AppLayout/AuthWrapper';

const initFormData: any = {
  password: '',
};

const VerifyPassword = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AuthContext);
  const [formData, setFormData] = useState<any>(initFormData);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState<any>('');
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [code, setCode] = useState<string | null>(null);

  const handleInputChange = (event: any) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    const codeVal =
      new URLSearchParams(location?.search).get('code') || (null as any);

    if (!codeVal) {
      navigate(ROUTES?.LOGIN);
    }
    setCode(encodeURIComponent(codeVal));
  }, []);

  const handleSubmit = (event: any) => {
    setErrorMsg('');
    setIsValid(true);
    event.preventDefault();

    if (!formData?.password && formData?.password === '') {
      setIsValid(false);

      return true;
    }
    setIsLoading(true);
    const payload = {
      password: formData?.password,
      code: code,
    };
    verifyPassword(payload)
      .then((response) => {
        console.log(
          '🚀 ~ .then ~ response:',
          response,
          response?.token,
          setIsAuthenticated
        );
        setIsLoading(false);

        if (response?.token && response?.token) {
          // setShowVerification(true);
          setIsAuthenticated(true);

          if (response?.data?.isSetBasicDetail) {
            console.log('home page');
            navigate(ROUTES.HOME);
          } else {
            console.log('organisation page');
            navigate(ROUTES.SET_DETAILS);
          }
        } else {
          console.log('object', response);

          setErrorMsg(response?.message || 'Something went wrong with login');
        }
      })
      .catch((error): any => {
        setIsLoading(false);
        setErrorMsg(
          error?.response?.data?.message || 'Something went wrong with login'
        );
      });
  };

  return (
    <AuthWrapper>
      <div className="form_height pt-8">
        <div className="w-full">
          <div className="flex-1 w-full">
            <h1 className="title_text">Password</h1>
            <p className="text-sm mt-1 font-normal text-[#475467]">
              Welcome! Please enter Password.
            </p>
            <form onSubmit={handleSubmit} className="h-[100%]">
              <div>
                <div className="flex flex-col">
                  <div className="relative mb-0 mt-3 lg:mt-0 xl:mt-2">
                    <label className="form_label fl-field-title-label text-[#000000B2]">
                      Password
                    </label>
                    <span className="text-[#000000B2] text-xs font-medium ms-[2px]">
                      *
                    </span>
                    <div className="relative">
                      <InputText
                        inputType={showPassword ? 'text' : 'password'}
                        inputName="password"
                        className={`input_text_auth pr-[36px] ${
                          !isValid
                            ? ' !border-[#FDA29B] '
                            : ' !border-[#D0D5DD] '
                        }`}
                        placeholder="Enter your password"
                        value={formData.password}
                        errorText={!isValid ? 'Password is required' : null}
                        onChangeFunc={handleInputChange}
                      />
                      <div
                        className="absolute right-3 top-3 cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <img src={eyeone} alt="opan" />
                        ) : (
                          <img src={eyeclose} alt="close" />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="relative">
                    <p className="text-sm text-[#D92D20]  dark:text-red-500">
                      {errorMsg}
                    </p>
                  </div>
                </div>
                {/* <div className="flex w-full p-0 mb-5 lg:mb-2 xl:mb-5 mt-2.5 ">
                  <div className="w-full items-center text-[#000000B2] text-[12px] font-medium">
                    <input
                      type="checkbox"
                      className="mr-2 mb-1 rounded-md border-[#D0D5DD]"
                    />
                    Remember me
                  </div>
                  <div className="w-full text-right  text-[#000000B2] text-[12px] font-medium">
                    <Link
                      className="cursor-pointer text-[#2422DD]  font-medium "
                      to={ROUTES.FORGOTPASSWORD}
                    >
                      Forgot Password?
                    </Link>
                  </div>
                </div> */}
                <ButtonCmp
                  type="submit"
                  disabled={isLoading}
                  className="btn_primary w-full mb-2 my-5"
                  loading={isLoading}
                >
                  Sign In
                </ButtonCmp>
              </div>
            </form>
          </div>
          <div className="mt-8 xxl:mt-8 xl:mt-4 lg:mt-3 flex gap-1 justify-center text-center text-sm">
            <span className="text-xs font-normal">Forgot Your Password?</span>
            <Link
              className="cursor-pointer font-semibold text-xs text-[#2422DD]"
              to={ROUTES.FORGOTPASSWORD}
            >
              Click Here
            </Link>
          </div>
        </div>
      </div>
    </AuthWrapper>
  );
};
export default VerifyPassword;
