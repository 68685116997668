import { Eye, EyeOff } from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import ErrorMsg from 'src/components/errorMsg';
import InputText from 'src/components/InputText/InputText';

const UpdatePasswordForm = ({
  formData,
  errorMsg,
  validForm,
  isLoading,
  handleSubmit,
  handleInputChange,
}: any) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-y-4">
      <div>
        <InputText
          label="Set Password"
          labelClassName="block mb-1.5"
          inputType={showPassword ? 'text' : 'password'}
          inputName="password"
          className={`pr-[36px] peer ${
            !validForm.password ? ' !border-[#FDA29B] ' : ' !border-[#D0D5DD] '
          }`}
          placeholder="Enter your password"
          value={formData.password}
          onChangeFunc={handleInputChange}
          icon={
            <div
              className="absolute top-1/2 -translate-y-1/2 right-3 cursor-pointer peer-placeholder-shown:pointer-events-none peer-placeholder-shown:text-gray400"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <Eye className="w-4 h-4 " />
              ) : (
                <EyeOff className="w-4 h-4 " />
              )}
            </div>
          }
        />
        <ErrorMsg
          errorText={
            !validForm.password &&
            (formData?.password == '' || formData?.password == null)
              ? 'Password is required'
              : formData?.password !== '' &&
                formData?.password !== null &&
                formData?.password?.length < 8
              ? 'Must Be at least 8 characters'
              : null
          }
        />
      </div>
      <div>
        <InputText
          label="Confirm Password"
          labelClassName="block mb-1.5"
          inputType={showConfirmPassword ? 'text' : 'password'}
          inputName="confirm_password"
          className={`pr-[36px] peer ${
            !validForm.password ? ' !border-[#FDA29B] ' : ' !border-[#D0D5DD] '
          }`}
          placeholder="Enter password confirmation"
          value={formData.confirm_password}
          errorText={
            !validForm.confirm_password &&
            (formData?.confirm_password == '' ||
              formData?.confirm_password == null)
              ? 'Confirm password is required'
              : formData?.confirm_password !== '' &&
                formData?.confirm_password !== null &&
                !validForm.confirm_password &&
                formData?.confirm_password !== formData?.password
              ? 'Password confirmation mismatch. Please verify.'
              : null
          }
          onChangeFunc={handleInputChange}
          icon={
            <div
              className="absolute top-1/2 -translate-y-1/2 right-3 cursor-pointer peer-placeholder-shown:pointer-events-none peer-placeholder-shown:text-gray400"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? (
                <Eye className="w-4 h-4 " />
              ) : (
                <EyeOff className="w-4 h-4 " />
              )}
            </div>
          }
        />
      </div>
      <ErrorMsg errorText={errorMsg} />

      <ButtonCmp
        type="submit"
        disabled={isLoading}
        className="btn_primary w-full mt-1"
        loading={isLoading}
      >
        Continue
      </ButtonCmp>
      {/* <p className="flex pt-5 gap-2 justify-center items-center">
        <img src={arrowright} alt="icon" className="w-4" />
        <Link
          className="cursor-pointer text-[#2422DD] text-xs font-semibold"
          to={`${ROUTES.LOGIN}`}
        >
          Back to Login
        </Link>
      </p> */}
    </form>
  );
};

export default UpdatePasswordForm;
