export const APP_ROOT = '/';

export const ROUTES = {
  HOME: `${APP_ROOT}`,
  LOGIN: `${APP_ROOT}login`,
  SET_DETAILS: `${APP_ROOT}set-details`,
  SIGN_UP: `${APP_ROOT}signup`,
  SET_PASSWORD: `${APP_ROOT}set-password`,
  CREATE_PASSWORD: `${APP_ROOT}create-password`,
  VERIFICATION: `${APP_ROOT}verification`,
  FORGOTPASSWORD: `${APP_ROOT}forogot-password`,
  RESET_PASSWORD: `${APP_ROOT}reset-password`,
  VERIFY_PASSWORD: `${APP_ROOT}verify-password`,
  MY_QUOTE: `${APP_ROOT}my-quote`,
  ADDRESS_DETAILS: `${APP_ROOT}my-quotes/address-details/:id?`,
  DIMENSIONS: `${APP_ROOT}my-quotes/dimensions/:id?`,
  QUOTE_DETAIL: `${APP_ROOT}my-quotes/:id?`,
};
