import {
  FilterLines,
  Plus,
  SearchLg,
  XClose,
  FileX01,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';
import CustomPagination from 'src/components/CustomPagination';
import DeleteModal from 'src/components/DeleteModal';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import TableCmp from 'src/components/TableCmp';
import { STATUS, TABLE_IDS } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { AuthContext } from 'src/context/AuthContext';
import {
  deleteQuote,
  listQuote,
  listQuoteCount,
  makeNoQuote,
} from 'src/services/QuoteService';
import {
  getSelectBoxOptions,
  isValidJSON,
  useRolePermission,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import CompareQuote from './CompareQuote';
import FilterModal from './FilterModal';
import QuotingDashboardRow from './QuotingDashboardRow';
import ToQuoteListLoading from './ToQuoteListLoading';

const initParams = {
  search: '',
  sortType: 'desc',
  // sortField: window?.MODE !== 'production' ? 'customerName' : 'createdAt',
  sortField: 'createdAt',
  page: 1,
  limit: 50,
  team: 'all',
  member: 'all',
  startDate: null,
  endDate: null,
  filter: {
    status: window?.MODE !== 'production' ? STATUS.OPENED : 'All',
    classification: null,
  },
};

const initCounter = {
  All: 0,
  Opened: 0,
  Active: 0,
  Won: 0,
  Lost: 0,
  Expired: 0,
  Archived: 0,
  NoQuote: 0,
};

const initAction = {
  mode: null,
  quote: false,
};

const recordsPerPageArray = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const MyQuote = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const uiState = useSelector((state: any) => state.MobileView);
  const { hasRoleV2 } = useRolePermission();
  const [quotes, setQuotes] = useState([]);
  const [totals, setTotals] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefresh, setIsRefresh] = useState(false);
  const [params, setParams] = useState(() => {
    const storedParams = localStorage.getItem(TABLE_IDS.QUOTE_LIST);

    return storedParams && isValidJSON(storedParams)
      ? JSON.parse(storedParams)
      : initParams;
  });
  const [counter, setCounter] = useState(initCounter);
  const [search, setSearch] = useState(() => {
    const storedParams = localStorage.getItem(TABLE_IDS.QUOTE_LIST);

    return storedParams && isValidJSON(storedParams)
      ? JSON.parse(storedParams)?.search || ''
      : '';
  });
  const [action, setAction] = useState(initAction);
  const [singleQuote, setSingleQuote] = useState<any>({});

  const [isDisplayCompareQuote, setIsDisplayCompareQuote] = useState(false);
  // const { currency, setCurrency } = useContext(BasicContext);
  const [isDisplayFilterModal, setIsDisplayFilterModal] = useState(false);
  const [filterCount, setFilterCount] = useState<number>(0);
  const [toQuoteCounter, setToQuoteCounter] = useState(0);
  const [quotedCounter, setQuotedCounter] = useState(0);
  const [reason, setReason] = useState<string>('');
  // const [currentFilterType, setCurrentFilterType] = useState('to-quote');
  const [currentFilterType, setCurrentFilterType] = useState(() => {
    const storedParams = localStorage.getItem(TABLE_IDS.QUOTE_LIST);

    return storedParams && isValidJSON(storedParams)
      ? JSON.parse(storedParams)?.filter?.status !== STATUS.OPENED &&
        JSON.parse(storedParams)?.filter?.status !== STATUS.WON
        ? 'All'
        : JSON.parse(storedParams)?.filter?.status
      : STATUS.OPENED;
  });

  const statuses = useMemo(
    () => [
      // {
      //   id: 1,
      //   value: 'All',
      //   name: 'All',
      //   counter: counter.All,
      // },
      // {
      //   id: 2,
      //   value: 'Opened',
      //   name: 'Open',
      //   counter: counter.Opened,
      // },
      {
        id: 3,
        value: 'Active',
        name: 'Quoted',
        counter: counter.Active,
      },
      {
        id: 4,
        value: 'Won',
        name: 'Won',
        counter: counter.Won,
      },
      {
        id: 5,
        value: 'Lost',
        name: 'Lost',
        counter: counter.Lost,
      },
      {
        id: 6,
        value: 'Expired',
        name: 'Expired',
        counter: counter.Expired,
      },
      {
        id: 7,
        value: 'Archived',
        name: 'Archived',
        counter: counter.Archived,
      },
      // {
      //   id: 8,
      //   value: 'NoQuote',
      //   name: 'No Quote',
      //   counter: counter.NoQuote,
      // },
    ],
    [counter]
  );

  const quoteTypeArray: any = useMemo(
    () => [
      {
        value: STATUS.OPENED,
        name: 'Requests',
        count: 0,
        // name: (
        //   <div data-value={STATUS.OPENED}>
        //     To-Quote
        //     <BadgeCmp
        //       type="primary"
        //       style="modern"
        //       dataValue={STATUS.OPENED}
        //       isHidePillDot
        //       mainClassName="bg-primary100 border-borderPrimary ml-2"
        //     >
        //       {toQuoteCounter}
        //     </BadgeCmp>
        //   </div>
        // ),
      },
      {
        value: 'All',
        name: 'Quotes',
        count: 0,
        // name: (
        //   <div data-value="All">
        //     Quoted
        //     <BadgeCmp
        //       type="primary"
        //       style="modern"
        //       dataValue="All"
        //       isHidePillDot
        //       mainClassName="bg-primary100 border-borderPrimary ml-2"
        //     >
        //       {quotedCounter}
        //     </BadgeCmp>
        //   </div>
        // ),
      },
    ],
    [toQuoteCounter, quotedCounter]
  );

  const statusArr = getSelectBoxOptions(statuses, 'value', 'name');

  useEffect(() => {
    let fCount = 0;
    if (params?.startDate && params?.endDate) fCount += 1;
    if (params?.team !== 'all') fCount += 1;
    if (params.member !== 'all') fCount += 1;
    if (
      params.filter?.classification &&
      params.filter?.classification !== 'all'
    )
      fCount += 1;
    // console.log('classification :>> ', classification);
    if (params.team !== 'all') console.log('team all :>> ');
    if (params.member !== 'all') console.log('userselected all :>> ');

    if (
      params.filter?.classification &&
      params.filter?.classification !== 'all'
    )
      if (uiState.isMobile || uiState.currentBreakpoint === 'SM') {
        if (params.filter?.status != 'All') fCount += 1;
      }

    setFilterCount(fCount);
  }, []);

  const getToQuotedCount = async () => {
    const toQuoteParams = {
      ...initParams,
      filter: {
        ...initParams.filter,
        status: STATUS.OPENED,
      },
    };

    try {
      const response: any = await listQuote({
        ...toQuoteParams,
        customerId: currentUser?.customerId?.id,
      });
      setToQuoteCounter(response.total);
    } catch (e: any) {
      //   setError(error.message);
      if (e.code === 'ERR_CANCELED') {
        return;
      }
    }
  };

  const getQuotedCount = async () => {
    const quoteParams = {
      ...initParams,
      filter: {
        ...initParams.filter,
        status: 'All',
      },
    };

    try {
      const response: any = await listQuote({
        ...quoteParams,
        customerId: currentUser?.customerId?.id,
      });
      setQuotedCounter(response.total);
    } catch (e: any) {
      if (e.code === 'ERR_CANCELED') {
        return;
      }
    }
  };

  useEffect(() => {
    getQuotedCount();
    getToQuotedCount();
    console.log('currentUser', currentUser?.customerId?.id);
  }, []);

  useEffect(() => {
    localStorage.removeItem('isModifyQuoteFromQuote');
    localStorage.removeItem('isPickupDateChanged');
    localStorage.removeItem('modifyQuoteCode');

    localStorage.setItem(TABLE_IDS.QUOTE_LIST, JSON.stringify(params));
    setIsLoading(true);

    const quoteApiController = new AbortController();
    const quoteApiSignal = quoteApiController.signal;

    const fetchQuotes = async () => {
      setIsLoading(false);
      setIsRefresh(false);

      try {
        setIsLoading(true);
        setQuotes([]);

        const [quotesResponse, quotesCountResponse]: [any, any] =
          await Promise.all([
            listQuote(
              { ...params, customerId: currentUser?.customerId?.id },
              quoteApiSignal
            ),
            listQuoteCount(
              { ...params, customerId: currentUser?.customerId?.id },
              quoteApiSignal
            ),
          ]);

        if (quotesResponse?.data) {
          setQuotes(quotesResponse.data?.quotes);

          if (quotesResponse.data?.count?.length) {
            setCounter(quotesResponse.data?.count[0]);
          }
        }

        setTotals(quotesCountResponse.total ?? 0);

        setIsLoading(false);
        setIsRefresh(false);
      } catch (e: any) {
        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setQuotes([]);
        setIsLoading(false);
        setIsRefresh(false);
      }
    };

    fetchQuotes();

    return () => {
      quoteApiController.abort();
    };
  }, [isRefresh, params, currentFilterType]);

  const searchDebounce = useCallback(
    debounce((debSearch: string) => {
      setParams((old: any) => ({ ...old, ...{ page: 1, search: debSearch } }));
    }, 700),
    []
  );

  const handleModalClose = useCallback(
    (status: boolean) => () => {
      setAction(initAction);
      setSingleQuote({});

      if (status) {
        deleteQuote(singleQuote.id)
          .then(() => {
            setIsRefresh(true);
            WalToast.success('Quote deleted successfully');
          })
          .catch(() => WalToast.error('Quote not deleted', ''));
      }
    },
    [singleQuote]
  );

  const handleReasonModalClose = (status: boolean) => {
    setAction(initAction);
    setSingleQuote({});

    if (status) {
      const payload = {
        quoteId: singleQuote.id,
        reason: reason,
      };
      makeNoQuote(payload)
        .then(() => {
          setIsRefresh(true);
          WalToast.success('Quote has been moved to no quote list');
        })
        .catch(() =>
          WalToast.error('Quote has not been moved to no quote', '')
        );
    }
    setReason('');
  };

  const headCellsQuoted = useMemo(
    () => [
      {
        id: 'fullName',
        name: 'User',
        sortable: true,
        visible: true,
        rowClassName: 'pl-[46px]',
      },
      {
        id: 'classification',
        name: 'Type',
        sortable: true,
        visible: true,
        rowClassName: '',
      },

      {
        id: 'shipperAddress1',
        name: 'Address',
        sortable: true,
        visible: true,
        rowClassName: 'text-xs ',
      },
      {
        id: 'createdAt',
        name: 'Creation Date',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'pickupDate',
        name: 'Pickup Date',
        sortable: true,
        visible: true,
        rowClassName: '!px-3',
      },
      {
        id: 'totalUnits',
        name: 'Total Units',
        sortable: true,
        visible: true,
        rowClassName: '!pl-3 !pr-1',
      },
      {
        id: 'totalWeight',
        name: 'Total Weight',
        sortable: true,
        visible: true,
        rowClassName: '!px-3',
      },
      // {
      //     id: "quotePrice",
      //     name: "Quote price",
      //     sortable: true,
      //     visible: true,
      // },
      {
        id: 'createdAt',
        name: 'Outstanding',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'action',
        visible: hasRoleV2('admin') ? true : false,
        rowClassName: 'pl-0',
      },
    ],
    []
  );

  const onRowClick = (id: number) => () => {
    navigate(`${PATH.QUOTE_DETAIL}/${id}`);
  };

  const onNewQuote = () => {
    navigate(`${PATH.ADDRESS_DETAILS}`);
  };

  const handleFilter = (event: any) => {
    const { name, value } = event.target;

    const trimmedValue = value.trim().replace(/\s+/g, ' ');

    if (name === 'searchQuote') {
      setSearch(value);
      searchDebounce(trimmedValue);
    }
  };

  const onClearFilter = () => {
    if (uiState.isMobile || uiState.currentBreakpoint === 'SM') {
      setParams(initParams);
    } else {
      setParams((old: any) => ({
        ...old,
        page: 1,
        startDate: null,
        endDate: null,
        team: 'all',
        member: 'all',
        filter: {
          ...old.filter,
          classification: null,
        },
      }));
    }

    setFilterCount(0);
  };

  const handlePagination = (page: number) => {
    setParams((old: any) => ({ ...old, page }));
  };

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title="Quoting Dashboard"
            desc="Create, view and manage your customer quotes."
            mainContainerClassName="flex-nowrap !flex-row"
            leftClassName="!w-auto"
            rightClassName="!w-auto"
          />
        }
      >
        <div className="min-h-full w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
          <div className="table-top-header ">
            <div className="table-left-wrap sm:w-auto w-full">
              <div className="table-title-wrap">
                <h5 className="table-title">All Quotes</h5>
                <BadgeCmp
                  style="modern"
                  type="success"
                  mainClassName={isLoading ? 'custom-loading' : ''}
                >
                  {totals} {totals > 1 ? 'Quotes' : 'Quote'}
                </BadgeCmp>
              </div>
              <p className="table-subtitle">
                Create, view and manage your customer quotes.
              </p>
            </div>

            <Link
              to={PATH.ADDRESS_DETAILS}
              className="btn no-animation text-xs min-h-[unset] h-auto font-semibold lg:py-[9px] py-2 shadow-sm btn-outline-primary flex sm:flex-none flex-1 "
            >
              <div className="flex items-center gap-2">
                <Plus className="text-primary w-4 h-4" />
                Create New Quote
              </div>
            </Link>
          </div>
          <div className="table-bottom-header items-center">
            <div className="table-header-bottom-left flex max-sm:w-full max-sm:flex-auto">
              {window.MODE !== 'production' && (
                <div className="flex flex-row xxl:gap-4 gap-3 justify-between w-full sm:items-center">
                  <TabButton
                    className=""
                    tabArray={quoteTypeArray}
                    isActive={currentFilterType}
                    activeClassName="!bg-utilityGray100"
                    handleOnClick={(e: any) => {
                      console.log(
                        'e?.target?.value',
                        e?.target?.dataset?.value
                      );
                      setParams((old: any) => ({
                        ...old,
                        ...{
                          filter: { status: e.target.dataset.value },
                          page: 1,
                          // search: '',
                          sortType: 'desc',
                          sortField: 'createdAt',
                        },
                      }));
                      setCurrentFilterType(e?.target?.dataset?.value);
                    }}
                  />
                </div>
              )}

              <InputText
                inputName="searchQuote"
                placeholder="Search"
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                value={search}
                inputType="text"
                onChangeFunc={handleFilter}
                parentClassName="table-searchInput"
                isClearable={true}
              />
            </div>

            <ButtonCmp
              type="submit"
              className={`btn_secondary_black table-filter-btn ${
                filterCount > 0
                  ? 'border-borderSecondary hover:border-borderSecondary'
                  : ''
              }`}
              onClick={(e) => {
                if (
                  e?.target?.id !== 'closeBtn' &&
                  e?.target?.parentElement?.id !== 'closeBtn'
                )
                  setIsDisplayFilterModal(true);
              }}
              icon={<FilterLines className=" w-4 h-4 " />}
            >
              Filters
              {filterCount > 0 ? (
                <>
                  <p className="filter-btn-selected">{filterCount}</p>
                  <XClose
                    className="w-4 h-4 text-primary700"
                    id="closeBtn"
                    onClick={(e) => {
                      e.preventDefault();
                      onClearFilter();
                    }}
                  />
                </>
              ) : (
                ''
              )}
            </ButtonCmp>
            <div className="table-recordsPerPage">
              <SelectBox
                name="recordsPerPageGroup"
                id="recordsPerPageGroup"
                className="form_control shadow"
                size="sm"
                options={recordsPerPageArray}
                onChangeFunc={(event: any) => {
                  setParams((old: any) => ({
                    ...old,
                    limit: event.value,
                    page: 1,
                  }));
                }}
                isSearchable={false}
                value={recordsPerPageArray.find(
                  (val: any) => val.value === params.limit
                )}
              />
            </div>
          </div>

          <div
            data-test-id="userTest"
            className="h-full lg:min-h-[122px] w-full border-t border-gray100 "
          >
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
              <TableCmp
                headCells={headCellsQuoted}
                tableDataArr={quotes}
                TableLoaderRowCmp={ToQuoteListLoading}
                TableRowCmp={QuotingDashboardRow}
                tableRowCmpProps={{
                  setAction: setAction,
                  setSingleQuote: setSingleQuote,
                  onRowClick: onRowClick,
                }}
                params={params}
                setParams={setParams}
                isTableDataLoading={isLoading}
                numberOfSkeletonRows={10}
                isTableRowClickable={true}
              />
            </div>
            {!isLoading &&
              params?.filter?.status === 'Archived' &&
              quotes.length <= 0 &&
              search && (
                <NotFoundUI
                  handleActionType={onNewQuote}
                  // buttonText="Add New Quote"
                  title={'No Archived Quotes found'}
                  desc={
                    'No archived quotes were found in the system based on the given search.'
                  }
                  containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
                  testId="wal-QuotingDashboard-dataNotFoundText"
                />
              )}
            {!isLoading && params?.filter?.status === 'Archived' && !search && (
              <NotFoundUI
                handleActionType={onNewQuote}
                // buttonText="Add New Quote"
                title={'Search Archived Quotes'}
                desc={
                  'Archived quotes will appear here after performing a search using the above search bar.'
                }
                containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
                testId="wal-QuotingDashboard-dataNotFoundText"
              />
            )}
            {quotes.length <= 0 &&
              !isLoading &&
              params?.filter?.status !== 'Archived' && (
                <NotFoundUI
                  handleActionType={onNewQuote}
                  buttonText="Add New Quote"
                  title="No Quotes found"
                  desc="There are no quotes found. You can create a new quote to get started."
                  containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
                  testId="wal-QuotingDashboard-dataNotFoundText"
                />
              )}
          </div>

          <div className="w-full bg-white rounded-b-xl">
            <CustomPagination
              recordsPerPage={params.limit}
              totalRecords={totals}
              currentPage={params.page}
              handlePagination={handlePagination}
            />
          </div>
        </div>
      </PageSectionLayout>

      {isDisplayCompareQuote && (
        <CompareQuote handleClose={() => setIsDisplayCompareQuote(false)} />
      )}
      {isDisplayFilterModal && (
        <FilterModal
          handleClose={() => setIsDisplayFilterModal(false)}
          params={params}
          setFilterCount={setFilterCount}
          setParams={setParams}
          statusArr={statusArr}
        />
      )}

      {action.mode === 'delete' && (
        <DeleteModal moduleName="Quote" handleClose={handleModalClose} />
      )}
      {action.mode === 'no-quote' && (
        // <DeleteModal moduleName="No Quote" handleClose={handleReasonModalClose} />
        <CommonModal
          title="No Quote"
          titleDesc={`Add description for no quote`}
          headerIcon={<FileX01 />}
          size="max-w-[600px]"
          handleClose={() => handleReasonModalClose(false)}
          primaryBtnOnClick={() => handleReasonModalClose(true)}
          secondaryBtnOnClick={() => handleReasonModalClose(false)}
          secondaryBtnText="Cancel"
          primaryBtnText="Confirm"
          primaryBtnLoading={isLoading}
          primaryBtnDisabled={isLoading}
        >
          <form action="" className="p-5">
            <InputText
              asTextarea={'textarea'}
              rows={10}
              inputName="reason"
              label="Reason for no quote"
              labelClassName="mb-1.5 block"
              className="h-20"
              placeholder="Please provide the reason for not proceeding with the quote."
              value={reason}
              onChangeFunc={(event: any) => setReason(event?.target?.value)}
            />
          </form>
        </CommonModal>
      )}
    </>
  );
};

export default MyQuote;
