import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { BasicContext } from 'src/context/BasicContext';

import { APP_FULL_NAME } from '../constants/common';
import { ROUTES } from '../constants/routes';
import { AuthContext } from '../context/AuthContext';

const PrivateRoute = ({ component: Component, title, ...props }: any) => {
  const { isAuthenticated, currentUser } = useContext(AuthContext);
  const { appearance } = useContext(BasicContext);

  useEffect(() => {
    document.title = `${title} - ${appearance.web_title ?? APP_FULL_NAME}`;
    const favicon = document.getElementById('favicon') as HTMLLinkElement;

    if (favicon) {
      favicon.href = appearance.faviconUrl + appearance.favicon;
    }
    console.log(currentUser);
  }, [title]);

  return isAuthenticated ? (
    !currentUser?.isSetBasicDetail ? (
      <Navigate to={ROUTES.SET_DETAILS} />
    ) : (
      <div className="md:ml-[var(--layout-sidebar-width)] bg-utilityGray300 overflow-hidden flex flex-col h-full">
        <p className="pt-[var(--layout-top-padding)]"></p>
        <div className="flex-grow overflow-y-auto h-full">
          <Component {...props} />
        </div>
      </div>
    )
  ) : (
    <Navigate to={ROUTES.LOGIN} />
  );
};

export default PrivateRoute;
