import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

// import Loader from '../../components/Loader';
import { ROUTES } from '../../constants/routes';
import { verify } from '../../services/AuthService';
import AuthWrapper from '../AppLayout/AuthWrapper';

const Verification = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [errorMsg, setErrorMsg] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let data = {
      code: encodeURIComponent(searchParams.get('code') as any),
    } as any;

    if (data?.code) {
      setIsLoading(true);

      verify(data)
        .then((response) => {
          setIsLoading(false);

          if (response?.id && response?.email) {
            navigate(
              `${ROUTES.SET_PASSWORD}?code=${encodeURIComponent(
                searchParams.get('code') as any
              )}`
            );
          } else {
            // need to handle error
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setErrorMsg(error?.response?.data?.message || `Something went wrong`);
        });
    } else {
      // navigate(ROUTES.LOGIN);
    }
  }, []);

  return (
    <>
      <AuthWrapper>
        {isLoading ? (
          <div className="form_height text-center">
            <div className="w-full">
              <span className="text-sm font-semibold text-[#475467]">
                Please hold on we are verify your account.
              </span>
            </div>
          </div>
        ) : (
          <div className="form_height text-center">
            <div className="w-full">
              <div className="w-full flex items-center justify-center pb-4"></div>
              <h1 className="title_text pb-4">
                {errorMsg !== ''
                  ? 'Email Verification Failed'
                  : 'Already Activated User'}
              </h1>
              <p className="text-[12px] text-[#475467] pb-4">{errorMsg}</p>
              <p className="flex pt-5 gap-2 justify-center items-center">
                {/* <img src={arrowright} alt="icon" className="w-4" /> */}
                <Link
                  className="cursor-pointer text-xs font-semibold text-[#2422DD]"
                  to={`${ROUTES.LOGIN}`}
                >
                  Back to Login
                </Link>
              </p>
            </div>
          </div>
        )}
      </AuthWrapper>
    </>
  );
};

export default Verification;
