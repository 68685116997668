import axios from 'axios';

const BaseApiUrl = window.API_URL;

export const axiosInterceptor = axios.create({
  baseURL: BaseApiUrl,
});

axiosInterceptor.interceptors.request.use(
  (config: any) => {
    if (localStorage.getItem('token')) {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    }

    if (localStorage.getItem('accessToken')) {
      config.headers.AccessToken = localStorage.getItem('accessToken');
    }

    return config;
  },
  (error: any) => Promise.reject(error)
);

axiosInterceptor.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // localStorage.removeItem('token');
      // localStorage.removeItem('accessToken');
      localStorage.clear();
      window.location.href = '/';
    }

    return Promise.reject(error);
  }
);

axiosInterceptor.interceptors.response.use(
  (response: any) => (response.status === 200 ? response.data : response),
  (error: any) => Promise.reject(error)
);
