import 'moment-timezone';

// eslint-disable-next-line import/order
import React from 'react';
import { Provider } from 'react-redux';
import './assets/css/app.css';
import './assets/css/prosemirror.css';
import './assets/scss/app.scss';
import 'react-toastify/dist/ReactToastify.css';

import Loader from './components/Loader';
import { AuthContext, AuthProvider } from './context/AuthContext';
import { BasicProvider } from './context/BasicContext';
import useKeyboardAdjustments from './hooks/useKeyboardAdjustments';
import { store } from './redux/store';
import Routes from './routes';
// moment.tz.setDefault(TIMEZONE);

function App() {
  useKeyboardAdjustments();

  return (
    <Provider store={store}>
      <AuthProvider>
        <BasicProvider>
          <AuthContext.Consumer>
            {({ loader }: any) => (loader ? <Loader /> : <Routes />)}
          </AuthContext.Consumer>
        </BasicProvider>
      </AuthProvider>
    </Provider>
  );
}

export default App;
