import React, { useContext } from 'react';
import { matchPath, Outlet } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';

import ToastBodyContainer from '../../components/ToastContainer';
import { AuthContext } from '../../context/AuthContext';

import Sidebar from './Sidebar';

const Layout = () => {
  const { isAuthenticated } = useContext(AuthContext);

  const pathsToMatch = [ROUTES.SET_DETAILS];
  const isDisplaySidebar = pathsToMatch.some((path) =>
    matchPath({ path }, location.pathname)
  );

  return (
    <div id="app-layout" className="p-10 shadow-lgc bg-utilityGray100">
      <div className="bg-white rounded-[30px] h-full overflow-auto custom-scrollbar-v2">
        {isAuthenticated && !isDisplaySidebar && <Sidebar />}
        <Outlet />
        <ToastBodyContainer />
      </div>
    </div>
  );
};

export default Layout;
