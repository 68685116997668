import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const getMapService = async (params: any = {}) => {
  const response = await axiosInterceptor.get(API.INTEGRATIONS.MAP_SERVICE, {
    params,
  });

  return response;
};
