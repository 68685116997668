import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from 'src/context/AuthContext';
import { resetNewPassword } from 'src/services/AuthService';

import { ROUTES } from '../../constants/routes';
import AuthWrapper from '../AppLayout/AuthWrapper';

import UpdatePasswordForm from './UpdatePasswordForm';

const initValidForm = {
  password: true,
  confirm_password: true,
};

const CreatePassword = () => {
  const location = useLocation();
  const { setIsAuthenticated } = useContext(AuthContext);
  const [validForm, setValidForm] = useState(initValidForm);
  const [code, setCode] = useState<string | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const initFormData: any = {
    email: '',
    password: '',
  };

  const [formData, setFormData] = useState<any>(initFormData);
  const [errorMsg, setErrorMsg] = useState<any>('');

  const valid = () => {
    let isValid = true;
    const validFormData = {
      password: true,
      confirm_password: true,
    };

    if (formData.password === '' || formData.password === null) {
      validFormData.password = false;
      isValid = false;
    }

    if (formData?.password && formData?.password?.length < 8) {
      validFormData.password = false;
      isValid = false;
    }

    if (
      formData?.confirm_password &&
      formData?.confirm_password !== formData?.password
    ) {
      validFormData.confirm_password = false;
      isValid = false;
    }

    setValidForm({ ...validForm, ...validFormData });

    return isValid;
  };

  const handleInputChange = (event: any) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event: any) => {
    setErrorMsg('');
    event.preventDefault();

    if (!valid()) {
      return true;
    }
    setIsLoading(true);
    const payload = {
      password: formData?.password,
      confirm_password: formData?.confirm_password,
      code: code,
    };

    resetNewPassword(payload)
      .then((response: any) => {
        setIsLoading(false);

        if (response?.token) {
          localStorage.setItem('token', response?.token || null);
          setIsAuthenticated(true);
          navigate(ROUTES.SET_DETAILS);
        }
      })
      .catch((error): any => {
        setIsLoading(false);
        setErrorMsg(
          error?.response?.message || 'Something went wrong with set password'
        );
      });
  };

  useEffect(() => {
    const codeVal =
      new URLSearchParams(location?.search).get('code') || (null as any);

    if (!codeVal) {
      navigate(ROUTES?.LOGIN);
    }
    setCode(encodeURIComponent(codeVal));
  }, []);

  return (
    <>
      <AuthWrapper
        authHeading="Create Password"
        authSubTitle="Secure your account with a strong password."
      >
        <UpdatePasswordForm
          formData={formData}
          errorMsg={errorMsg}
          validForm={validForm}
          isLoading={isLoading}
          handleSubmit={handleSubmit}
          handleInputChange={handleInputChange}
        />
      </AuthWrapper>
    </>
  );
};

export default CreatePassword;
