import React, { useState } from 'react';
import ErrorMsg from 'src/components/errorMsg';

import ButtonCmp from '../../components/ButtonCmp';
import InputText from '../../components/InputText/InputText';
import { ROUTES } from '../../constants/routes';
import { login } from '../../services/AuthService';
import './login.scss';
import AuthWrapper from '../AppLayout/AuthWrapper';

import VerifyOtp from './VerifyOtp';

const initValidForm = {
  email: true,
  isEmailValid: true,
};

const Login = () => {
  // const { setIsAuthenticated } = useContext(AuthContext);
  const [validForm, setValidForm] = useState(initValidForm);
  const [isLoading, setIsLoading] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const initFormData: any = {
    email: '',
  };

  const [formData, setFormData] = useState<any>(initFormData);
  const [errorMsg, setErrorMsg] = useState<any>('');
  const [showVerification, setShowVerification] = useState(false);

  const valid = () => {
    let isValid = true;
    const validFormData = {
      email: true,
      isEmailValid: true,
      password: true,
    };
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g;

    if (formData.email === '' || formData.email === null) {
      validFormData.email = false;
      isValid = false;
    }

    if (formData.email && !emailRegex.test(formData.email)) {
      validFormData.isEmailValid = false;
      isValid = false;
    }

    if (formData.password === '' || formData.password === null) {
      validFormData.password = false;
      isValid = false;
    }

    setValidForm({ ...validForm, ...validFormData });

    return isValid;
  };

  const handleInputChange = (event: any) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event: any) => {
    setErrorMsg('');
    event.preventDefault();

    if (!valid()) {
      return true;
    }
    setIsLoading(true);
    login(formData)
      .then((response) => {
        console.log('🚀 ~ .then ~ response:', response, response?.token);
        setIsLoading(false);
        setShowVerification(true);
        if (response?.data && response?.data?.password)
          setRedirectUrl(ROUTES.VERIFY_PASSWORD);
        else setRedirectUrl(ROUTES.CREATE_PASSWORD);

        // if (response?.data?.isActive) {
        //   // if (response?.code && response?.code) {
        //   setShowVerification(true);
        //   // setIsAuthenticated(true);
        //   // navigate(ROUTES.HOME);
        //   // } else {
        //   //   console.log('object', response);

        //   //   setErrorMsg(response?.message || 'Something went wrong with login');
        //   // }
        // } else {
        //   navigate(ROUTES.REGISTER);
        // }
      })
      .catch((error): any => {
        setIsLoading(false);
        setErrorMsg(
          error?.response?.data?.message || 'Something went wrong with login'
        );
      });
  };

  return (
    <>
      {!showVerification ? (
        <AuthWrapper
          authHeading="Login"
          authSubTitle="Welcome! Please enter your email."
        >
          <>
            <form onSubmit={handleSubmit} className="flex flex-col gap-y-4">
              <div>
                <InputText
                  label="Email"
                  labelClassName="block mb-1.5"
                  inputName="email"
                  className={`input_text_auth${
                    !validForm.email || !validForm?.isEmailValid
                      ? ' !border-[#FDA29B] '
                      : ' !border-[#D0D5DD] '
                  }`}
                  placeholder="Enter your email"
                  value={formData.email}
                  onChangeFunc={handleInputChange}
                  errorText={
                    !validForm.email
                      ? 'Email address is required'
                      : !validForm.isEmailValid
                      ? 'Please enter valid email address'
                      : null
                  }
                  required
                />
                <ErrorMsg errorText={errorMsg} />
              </div>
              <ButtonCmp
                type="submit"
                disabled={isLoading}
                className="btn_primary w-full mt-1"
                loading={isLoading}
              >
                Continue
              </ButtonCmp>
            </form>
          </>
        </AuthWrapper>
      ) : (
        <VerifyOtp
          redirectUrl={redirectUrl}
          email={formData?.email}
          setShowVerification={setShowVerification}
        />
      )}
    </>
  );
};

export default Login;
