import React from 'react';

import Loginlogo from '../../assets/img/logo2.svg';

const AuthWrapper = ({ children, authHeading, authSubTitle }: any) => (
  <>
    <ul className="flex min-h-full">
      <li className="w-[35%] p-4">
        <div className="max-w-[380px] mx-auto mt-[16vh] ">
          <div className="rounded border border-utilityGray200 bg-gray50 inline-block px-1.5 pt-1.5 pb-[7px]">
            <img src={Loginlogo} alt="logo-dark" className="" />
          </div>
          <h6 className="text-utilityBlack600 text-3xl font-semibold mt-6">
            {authHeading}
          </h6>
          <p className="text-utilityTertiary600 text-sm font-normal mt-1 mb-8">
            {authSubTitle}
          </p>
          {children}
        </div>
      </li>
      <li className="flex-1 bg-[url('../../assets/img/auth-truck.webp')] bg-cover bg-center bg-no-repeat rounded-[30px] flex items-end relative overflow-hidden after:content-[''] after:bg-[linear-gradient(180deg,_rgba(0,_0,_0,_0.00)_5.12%,_rgba(0,_0,_0,_0.50)_83.55%)] after:h-full after:w-full after:absolute after:top-0 after:left-0 ">
        <div className="text-white p-12 z-[1]">
          <h5 className="mb-6 text-4xl font-semibold tracking-[-0.924px]">
            Welcome to the Western Alliance <br />
            Customer Portal
          </h5>
          <p className="text-base font-medium">
            Lorem Ipsum has been the industrys standard dummy text ever since
            the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type
          </p>
        </div>
      </li>
    </ul>
  </>
);

export default AuthWrapper;
