import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import ButtonCmp from '../../components/ButtonCmp';
import InputText from '../../components/InputText/InputText';
import { ROUTES } from '../../constants/routes';
import { sendVerificationMail } from '../../services/AuthService';
import './login.scss';
import AuthWrapper from '../AppLayout/AuthWrapper';

import VerifyOtp from './VerifyOtp';
// import bgfram from '../../assets/img/for-grd-img.svg';

const initValidForm = {
  email: true,
  isEmailValid: true,
};

const ForgotPassword = () => {
  const [validForm, setValidForm] = useState(initValidForm);
  const [showVerification, setShowVerification] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const initFormData: any = {
    email: '',
  };

  const [formData, setFormData] = useState<any>(initFormData);
  const [errorMsg, setErrorMsg] = useState<any>('');

  const valid = () => {
    let isValid = true;
    const validFormData = {
      email: true,
      isEmailValid: true,
      otp: true,
    };
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g;

    if (formData.email === '' || formData.email === null) {
      validFormData.email = false;
      isValid = false;
    }

    if (formData.email && !emailRegex.test(formData.email)) {
      validFormData.isEmailValid = false;
      isValid = false;
    }

    setValidForm({ ...validForm, ...validFormData });

    return isValid;
  };

  const handleInputChange = (event: any) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event: any) => {
    setErrorMsg('');
    event.preventDefault();

    if (!valid()) {
      return true;
    }
    setIsLoading(true);
    sendVerificationMail(formData)
      .then((response) => {
        console.log('🚀 ~ .then ~ response:', response, response?.token);
        setIsLoading(false);

        if (response?.token && response?.token) {
          setShowVerification(true);
          // navigate(ROUTES.HOME);
        } else {
          console.log('object', response);

          setErrorMsg(response?.message || 'Something went wrong with login');
        }
      })
      .catch((error): any => {
        setIsLoading(false);
        setErrorMsg(
          error?.response?.data?.message || 'Something went wrong with login'
        );
      });
  };

  return (
    <>
      {!showVerification ? (
        <AuthWrapper>
          <div className="form_height pt-8">
            <div className="w-full">
              <div className="flex-1 w-full">
                <h1 className="title_text">Forgot Password</h1>
                <p className="text-sm mt-1 font-normal text-[#475467]">
                  No worries, we’ll send you reset instructions.
                </p>
                <form onSubmit={handleSubmit} className="h-[100%]">
                  <div>
                    <div className="flex flex-col">
                      {/* <label className="fl-field-title-label font-bold text-base my-2 md:my-3">Email</label> */}
                      <div className="relative mb-0 mt-5 lg:mt-4 xl:mt-5">
                        <label className="form_label fl-field-title-label text-[#000000B2]">
                          Email
                        </label>
                        <span className="text-[#000000B2] text-xs ms-[2px]">
                          *
                        </span>
                        <div className="relative">
                          <InputText
                            inputName="email"
                            className={`input_text_auth${
                              !validForm.email || !validForm?.isEmailValid
                                ? ' !border-[#FDA29B] '
                                : ' !border-[#D0D5DD] '
                            }`}
                            placeholder="Enter your email"
                            value={formData.email}
                            onChangeFunc={handleInputChange}
                            errorText={
                              !validForm.email
                                ? 'Email address is required'
                                : !validForm.isEmailValid
                                ? 'Please enter valid email address'
                                : null
                            }
                          />
                        </div>
                      </div>

                      <div className="relative">
                        <p className="text-sm text-[#D92D20]  dark:text-red-500">
                          {errorMsg}
                        </p>
                      </div>
                    </div>

                    <ButtonCmp
                      type="submit"
                      disabled={isLoading}
                      className="btn_primary w-full my-4"
                      loading={isLoading}
                    >
                      Send Verification Code
                    </ButtonCmp>

                    <div className="w-full text-center  text-[#000000B2] text-[12px] font-medium">
                      <Link
                        className="cursor-pointer text-[#2422DD]  font-medium "
                        to={ROUTES.LOGIN}
                      >
                        Back
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="hidden lg:flex md:flex-row lg:flex-row mt-8 lg:mt-0 lg:absolute lg:bottom-2 xl:bottom-5 lg:w-[94%] xl:w-[94%] lg:justify-between">
            <div className="w-full text-left items-center font-normal text-[#000000B2] text-[12px]">
              <Link to="/">2024 Western Alliance Inc..</Link>
            </div>
            <div className="flex items-center text-[#2422DD] text-[12px] font-semibold">
              <ul className="mt-0 flex justify-between items-center w-max">
                <li>
                  <Link to="/">Terms and Conditions</Link>
                </li>
                <li className="list-disc ml-6 lg:ml-5 xl:ml-6">
                  <Link to="/">Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </div>
        </AuthWrapper>
      ) : (
        <VerifyOtp
          redirectUrl={ROUTES.SET_PASSWORD}
          email={formData?.email}
          setShowVerification={setShowVerification}
        />
      )}
    </>
  );
};

export default ForgotPassword;
